import { Component, Renderer2, ElementRef } from '@angular/core';

@Component({
  selector: 'app-timebar',
  templateUrl: './timebar.component.html',
  styles: []
})
export class TimebarComponent {

  constructor(
    private renderer: Renderer2,
    private element: ElementRef) { }

    startTimer(time) {
      this.renderer.setStyle(this.element.nativeElement.children[0], 'transition', 'width ' + time + 'ms linear');

      setTimeout(() => {
        this.renderer.setStyle(this.element.nativeElement.children[0], 'width', '0%');
      }, 0);

  }

}
