import { Injectable } from '@angular/core';



@Injectable({
  providedIn: 'root'
})
export class FlashService {


  constructor() { }

  show(message: string, duration = 3000, type?: FlashMessageType) { }

  hide() { }
}

export const enum FlashMessageType {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger'
}
