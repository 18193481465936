import { Injectable } from '@angular/core';
import { Portal } from '../models/portal.interface';

@Injectable({
  providedIn: 'root',
})
export class PortalService {
  private activePortal: Portal;
  private portals: Portal[] = [
    {
      name: 'Seminar 2018',
      title: 'Popularmusikverband - Seminar 2018',
      p: 'bunterabend2018',
      primaryColor: 'rgb(0,95,180)',
      hero: '../../../assets/images/hero.jpg',
      backgroundImage: 'assets/images/bg.jpg',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      bodyClass: 'seminar-page',
      siteRoot: 'seminar2018',
      videoRoot: 'seminar',
      videos: [
        {
          thumbnail: '03.jpg',
          title: 'My Lighthouse',
          artist: 'Petra',
          videoFile: '03-Petra-MyLighthouse.mp4',
        },
        {
          thumbnail: '04.jpg',
          title: 'Psalm 177',
          artist: 'Susanne Frankholz',
          videoFile: '04-SusanneFrankholz-Psalm117.mp4',
        },
        {
          thumbnail: '05.jpg',
          title: 'Alles wird gut',
          artist: 'Susanne Frankholz',
          videoFile: '05-SusanneFrankholz-AllesWirdGut.mp4',
        },
        {
          thumbnail: '07.jpg',
          title: 'Halleluja',
          artist: 'Ternim',
          videoFile: '07-Halleluja.mp4',
        },
        {
          thumbnail: '08.jpg',
          title: 'Ternim',
          artist: 'Ternim',
          videoFile: '08-Ternim.mp4',
        },
        {
          thumbnail: '10.jpg',
          title: 'Mbube / the lion',
          artist: 'mix &apos;n&apos; free',
          videoFile: '10-mixnfree-Mbube.mp4',
        },
        {
          thumbnail: '11.jpg',
          title: 'Summer Wine',
          artist: 'Spontanband',
          videoFile: '11-Spontanband-SummerWine.mp4',
        },
        {
          thumbnail: '13.jpg',
          title: 'Pray',
          artist: 'van Dooren / Ammon',
          videoFile: '13-vanDooren_Ammon-Pray.mp4',
        },
        {
          thumbnail: '14.jpg',
          title: 'Ain&apos;t no mountain',
          artist: 'van Dooren / Ehrensperger',
          videoFile: '14-vanDooren_Ehrensperger-AintNoMountain.mp4',
        },
        {
          thumbnail: '16.jpg',
          title: 'Umsonst',
          artist: 'Christian Probst',
          videoFile: '16-ChristianProbst-Umsonst.mp4',
        },
        {
          thumbnail: '17.jpg',
          title: 'Nadelöhr',
          artist: 'WanWan Mey',
          videoFile: '17-WanWanMey-Nadeloehr.mp4',
        },
        {
          thumbnail: '18.jpg',
          title: 'Nachtgebet',
          artist: 'WanWan Mey',
          videoFile: '18-WanWanMey-Nachtgebet.mp4',
        },
        {
          thumbnail: '19.jpg',
          title: 'Du bist immer da',
          artist: 'Andreas Buchner',
          videoFile: '19-AndreasBuchner-DuBistImmerDa.mp4',
        },
        {
          thumbnail: '20.jpg',
          title: 'Gott segne Dich',
          artist: 'Ehrensperger ... ',
          videoFile: '20-GottSegneDich.mp4',
        },
      ],
    },
    {
      name: 'Schülerkonzert 2018',
      title: 'Vorspiel am 21. November 2018',
      p: 'cellokonzert2018',
      primaryColor: 'rgb(0,95,180)',
      hero: '../../../assets/images/hero-cello@2x.jpg',
      backgroundImage: 'assets/images/bg.jpg',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      bodyClass: 'cello2018-page',
      siteRoot: 'schuelerkonzert2018',
      videoRoot: 'konzerte/cello2018',
      videos: [
        {
          thumbnail: '01.jpg',
          title: 'Mr. Bass',
          artist: 'Andra Offermann',
          videoFile: '01-Andrea-MrBass.mp4',
        },
        {
          thumbnail: '02.jpg',
          title: 'Mango Walk',
          artist: 'Malte Kamp',
          videoFile: '02-Malte-MangoWalk.mp4',
        },
        {
          thumbnail: '03.jpg',
          title: 'Holiday',
          artist: 'Yamina Tabor',
          videoFile: '03-Yamina-Holiday.mp4',
        },
        {
          thumbnail: '04.jpg',
          title: 'Die Moldau (Smetana)',
          artist: 'Emilia Schmid',
          videoFile: '04-Emilia-DieMoldau.mp4',
        },
        {
          thumbnail: '05.jpg',
          title: 'Medieval Tale & Chase In The Dark',
          artist: 'Mia Prohammer',
          videoFile: '05-Mia-MedievalTale-ChaseInTheDark.mp4',
        },
        {
          thumbnail: '06.jpg',
          title: 'Scarborough Fair',
          artist: 'Alexander Koch',
          videoFile: '06-Alex-ScarboroughFair.mp4',
        },
        {
          thumbnail: '07.jpg',
          title: 'Golliwogs Cake Walk (Debussy)',
          artist: 'Joachim Emde',
          videoFile: '07-Joachim-GolliwogsCakeWalk.mp4',
        },
        {
          thumbnail: '08.jpg',
          title: 'Old Grizzly&apos;s Blues',
          artist: 'Malte, Mia, Andra, Emilia, Anna',
          videoFile: '08-Ensemble1-OldGrizzlysBlues.mp4',
        },
        {
          thumbnail: '09.jpg',
          title: 'Groove It!',
          artist: 'Yamina, Joachim, Alma, Alex',
          videoFile: '09-Ensemble2-GrooveIt.mp4',
        },
        {
          thumbnail: '10.jpg',
          title: 'The Champion',
          artist: 'Emilia, Alex',
          videoFile: '10-Band-TheChampion.mp4',
        },
      ],
    },
    {
      name: 'Sommerkonzert 2019',
      title: 'Vorspiel am 17. Juni 2019',
      p: 'sommerkonzert2019',
      primaryColor: 'rgb(0,95,180)',
      hero: '../../../assets/images/hero-cello-v2.jpg',
      backgroundImage: 'assets/images/bg.jpg',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      bodyClass: 'cello2019sommer-page',
      siteRoot: 'cellosommer2019',
      videoRoot: 'konzerte/cello2019sommer',
      videos: [
        {
          thumbnail: '01.jpg',
          title: 'Show Stopper',
          artist: 'Mia',
          videoFile: '01-Mia-ShowStopper.mp4',
        },
        {
          thumbnail: '02.jpg',
          title: 'Aura Lee',
          artist: 'Sophie',
          videoFile: '02-Sophie-AuraLee.mp4',
        },
        {
          thumbnail: '03.jpg',
          title: 'Gavotte und Bourée',
          artist: 'Emilia',
          videoFile: '03-Emilia-Gavotte.mp4',
        },
        {
          thumbnail: '04.jpg',
          title: 'Chase In The Dark',
          artist: 'Anna',
          videoFile: '04-Anna-ChaseInTheDark.mp4',
        },
        {
          thumbnail: '05.jpg',
          title: '„Te deum“ von Charpentier',
          artist: 'Andra',
          videoFile: '05-Andra-TeDeum.mp4',
        },
        {
          thumbnail: '06.jpg',
          title: 'Coming Home',
          artist: 'Malte',
          videoFile: '06-Malte-ComingHome.mp4',
        },
        {
          thumbnail: '07.jpg',
          title: '3 Mäuse Walzer',
          artist: 'Mia, Emilia, Andra',
          videoFile: '07-3Maeuse.mp4',
        },
        {
          thumbnail: '08.jpg',
          title: 'Game Of Thrones',
          artist: 'Yamina',
          videoFile: '08-Yamina-GameOfThrones.mp4',
        },
        {
          thumbnail: '09.jpg',
          title: 'Sarabande von G. F. Händel',
          artist: 'Alex',
          videoFile: '09-Alex-Sarabande.mp4',
        },
        {
          thumbnail: '10.jpg',
          title: '3 Sätze aus der 6. Sonate von Boismortier',
          artist: 'Joachim',
          videoFile: '10-Joachim-Sonate.mp4',
        },
        {
          thumbnail: '11.jpg',
          title: 'Holiday Rag',
          artist: 'Ensemble',
          videoFile: '11-Ensemble-HolidayRag.mp4',
        },
        {
          thumbnail: '12.jpg',
          title: 'Ein Tag in Venedig / House of the Rising Sun',
          artist: 'Ensemble',
          videoFile: '12-Ensemble-Venedig-RisingSun.mp4',
        },
      ],
    },
    {
      name: 'Herbstkonzert 2019',
      title: 'Vorspiel am 21. November 2019',
      p: 'celloherbst2019',
      primaryColor: 'rgb(0,95,180)',
      hero: '../../../assets/images/hero-cello-v2.jpg',
      backgroundImage: 'assets/images/bg.jpg',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      bodyClass: 'cello2019herbst-page',
      siteRoot: 'celloherbst2019',
      videoRoot: 'konzerte/cello2019herbst',
      videos: [
        {
          thumbnail: '01.jpg',
          title: '„Sarabande“ von G. F. Händel',
          artist: 'Yamina',
          videoFile: '01-Sarabande.mp4',
        },
        {
          thumbnail: '02.jpg',
          title: '„Belle“ aus Die Schöne und das Biest',
          artist: 'Emilia',
          videoFile: '02-Belle.mp4',
        },
        {
          thumbnail: '03.jpg',
          title: 'Some Day',
          artist: 'Alex',
          videoFile: '03-SomeDay.mp4',
        },
        {
          thumbnail: '04.jpg',
          title: 'Spy Movie',
          artist: 'Anna',
          videoFile: '04-SpyMovie.mp4',
        },
        {
          thumbnail: '05.jpg',
          title: 'Pick a bale of cotton',
          artist: 'Sophie',
          videoFile: '05-PickABaleOfCotton.mp4',
        },
        {
          thumbnail: '06.jpg',
          title: 'Das Lummerlandlied',
          artist: 'Sophie',
          videoFile: '06-Lummerlandlied.mp4',
        },
        {
          thumbnail: '07.jpg',
          title: 'Rondo',
          artist: 'Joachim',
          videoFile: '07-Rondo.mp4',
        },
        {
          thumbnail: '08.jpg',
          title: 'Falling Leaves',
          artist: 'Mimi',
          videoFile: '08-FallingLeaves.mp4',
        },
        // {
        //   thumbnail: '09.jpg',
        //   title: 'Over the water',
        //   artist: 'Vroni',
        //   videoFile: '09-OverTheWater.mp4'
        // },
        {
          thumbnail: '10.jpg',
          title: 'Allegro ma non troppo',
          artist: 'Andra',
          videoFile: '10-Allegro.mp4',
        },
        // {
        //   thumbnail: '11.jpg',
        //   title: 'Riversong',
        //   artist: 'Alma',
        //   videoFile: '11-Riversong.mp4'
        // },
        // {
        //   thumbnail: '12.jpg',
        //   title: 'Vortragsstück „Cantabile“',
        //   artist: 'Alma',
        //   videoFile: '12-Cantabile.mp4'
        // },
        {
          thumbnail: '13.jpg',
          title: 'Game Of Thrones',
          artist: 'Alex',
          videoFile: '13-GameOfThrones.mp4',
        },
        {
          thumbnail: '14.jpg',
          title: 'Sound of Silence',
          artist: 'Joachim, Alex, Benjamin',
          videoFile: '14-SoundOfSilence.mp4',
        },
        {
          thumbnail: '15.jpg',
          title: 'Take it easy',
          artist: 'Ensemble',
          videoFile: '15-TakeItEasy.mp4',
        },
        {
          thumbnail: '16.jpg',
          title: 'Dubadabda - Kanon',
          artist: 'Ensemble',
          videoFile: '16-Dubadabda.mp4',
        },
      ],
    },
    {
      name: 'Sommerkonzert 2021',
      title: 'Vorspiel am 14. Juli 2021',
      p: 'cellosommer2021',
      primaryColor: 'rgb(0,95,180)',
      hero: '../../../assets/images/hero-cello-v2.jpg',
      backgroundImage: 'assets/images/bg.jpg',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      bodyClass: 'cello2021sommer-page',
      siteRoot: 'cellosommer2021',
      videoRoot: 'konzerte/cello2021sommer',
      videos: [
        {
          thumbnail: '01.jpg',
          title: '„Medieval tale“ & „Chase in the dark“',
          artist: 'Nadine',
          videoFile: '01-Nadine.mp4',
        },
        {
          thumbnail: '02.jpg',
          title: '„Duett“ von J. Offenbach',
          artist: 'Vroni',
          videoFile: '02-Vroni.mp4',
        },
        {
          thumbnail: '03.jpg',
          title: '„Elegie“ von G. Fauré',
          artist: 'Benjamin',
          videoFile: '03-Benjamin.mp4',
        },
        {
          thumbnail: '04.jpg',
          title: '„Duett“ von F. A. Kummer',
          artist: 'Joachim',
          videoFile: '04-Joachim.mp4',
        },
        {
          thumbnail: '05.jpg',
          title: '„Valse Nr.2“ von D. Schostakowitsch',
          artist: 'Andra und Meike',
          videoFile: '05-AndraUndMeike.mp4',
        },
        {
          thumbnail: '06.jpg',
          title: '„Who you really are“ aus der Serie „Sherlock“',
          artist: 'Yamina',
          videoFile: '06-Yamina.mp4',
        },
        {
          thumbnail: '07.jpg',
          title: '„Cats Eyes“',
          artist: 'Zoe',
          videoFile: '07-Zoe.mp4',
        },
        {
          thumbnail: '08.jpg',
          title:
            '„Lullaby Of Woe“ aus dem Spiel Witcher 3 und „Gesang der Vögel“ von P. Casals',
          artist: 'Alex',
          videoFile: '08-Alex.mp4',
        },
        {
          thumbnail: '09.jpg',
          title: '„Concerto“ von F. Couperin',
          artist: 'Annika',
          videoFile: '09-Annika.mp4',
        },
        {
          thumbnail: '10.jpg',
          title: '„Old Grizzly‘s Blues“ von G. Koeppen',
          artist: 'Ensemble',
          videoFile: '10-Ensemble.mp4',
        },
        {
          thumbnail: '11.jpg',
          title: '„Funk Or What?“ von G. Koeppen',
          artist: 'Ensemble',
          videoFile: '11-Ensemble.mp4',
        },
      ],
    },
    {
      name: 'Herbstkonzert 2021',
      title: 'Vorspiel am 17. November 2021',
      p: 'celloherbst2021',
      primaryColor: 'rgb(0,95,180)',
      hero: '../../../assets/images/hero-cello-v2.jpg',
      backgroundImage: 'assets/images/bg.jpg',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      bodyClass: 'cello2021herbst-page',
      siteRoot: 'celloherbst2021',
      videoRoot: 'konzerte/cello2021herbst',
      videos: [
        {
          thumbnail: '01.jpg',
          title: '„Andante Cantabile“ von F. Dozauer',
          artist: 'Zoe',
          videoFile: '01-Zoe.mp4',
        },
        {
          thumbnail: '02.jpg',
          title: '„„Spiegelkanon“ von W. A. Mozart',
          artist: 'Vroni',
          videoFile: '02-Vroni.mp4',
        },
        {
          thumbnail: '03.jpg',
          title: '„Ungarischer Tanz“ von Joh. Brahms',
          artist: 'Yamina',
          videoFile: '03-Yamina.mp4',
        },
        {
          thumbnail: '04.jpg',
          title:
            '„Die Geschichte vom kleinen Bären“ und andere Variationen von S. Tiefensee',
          artist: 'Joachim',
          videoFile: '04-Joachim.mp4',
        },
        {
          thumbnail: '05.jpg',
          title: '„Der Schwan“ von C. Saint-Saëns',
          artist: 'Annika',
          videoFile: '05-Annika.mp4',
        },
        {
          thumbnail: '06.jpg',
          title: '„Song For Sienna“ von Brian Crain',
          artist: 'Alex',
          videoFile: '06-Alex.mp4',
        },
        {
          thumbnail: '07.jpg',
          title: '„Londonderry Air“ (Oh Danny Boy)',
          artist: 'Ensemble',
          videoFile: '07-LondonderryAir.mp4',
        },
        {
          thumbnail: '08.jpg',
          title: '„Weihnachtsmedley“ (Es wird scho glei dumpa und First Noel)',
          artist: 'Ensemble',
          videoFile: '08-Weihnachtsmedley.mp4',
        },
      ],
    },
    {
      name: 'Sommerkonzert 2022',
      title: 'Vorspiel am 6. Juli 2022',
      p: 'cellosommer2022',
      primaryColor: 'rgb(0,95,180)',
      hero: '../../../assets/images/hero-cello-v3.jpg',
      backgroundImage: 'assets/images/bg.jpg',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      bodyClass: 'cello2022sommer-page',
      siteRoot: 'cellosommer2022',
      videoRoot: 'konzerte/cello2022sommer',
      videos: [
        {
          thumbnail: '01.jpg',
          title: '„Scarborough Fair“',
          artist: 'Zoe',
          videoFile: '01-Zoe.mp4',
        },
        {
          thumbnail: '02.jpg',
          title: '„On the go“ und „Pick a bale of cotton“',
          artist: 'Cornelia',
          videoFile: '02-Cornelia.mp4',
        },
        {
          thumbnail: '03.jpg',
          title: '„Sonate 1. & 2. Satz“ von Boismortier',
          artist: 'Vroni',
          videoFile: '03-Vroni.mp4',
        },
        {
          thumbnail: '04.jpg',
          title: '„Die Moldau“ von B. Smetana',
          artist: 'Simone, Annika, Vroni, Cornelia',
          videoFile: '04-Moldau.mp4',
        },
        {
          thumbnail: '05.jpg',
          title: '„Menuett 1 & 2“ aus der G-Dur Suite von J. S. Bach',
          artist: 'Annika',
          videoFile: '05-Annika.mp4',
        },
        {
          thumbnail: '06.jpg',
          title: '„Kanon Sonate“ von G.F. Telemann, 1. & 2. Satz',
          artist: 'Joachim',
          videoFile: '06-Joachim.mp4',
        },
        {
          thumbnail: '07.jpg',
          title: '„Kanon Sonate“ von G.F. Telemann, 3. Satz',
          artist: 'Benjamin',
          videoFile: '07-Benjamin.mp4',
        },
        {
          thumbnail: '08.jpg',
          title: '„Je pense à toi“ und „Latin Nights“',
          artist: 'Alex',
          videoFile: '08-Alex.mp4',
        },
        {
          thumbnail: '09.jpg',
          title: '„Kanon in D“ von Johann Pachelbel',
          artist: 'Yamina',
          videoFile: '09-Yamina.mp4',
        },
        {
          thumbnail: '10.jpg',
          title: '„Kanon“ von Beethoven',
          artist: 'Ensemble',
          videoFile: '10-Beethoven.mp4',
        },
        {
          thumbnail: '11.jpg',
          title: '„Annie Laurie“',
          artist: 'Ensemble',
          videoFile: '11-AnnieLaurie.mp4',
        },
      ],
    },
    {
      name: 'Herbstkonzert 2022',
      title: 'Vorspiel am 16. November 2022',
      p: 'celloherbst2022',
      primaryColor: 'rgb(0,95,180)',
      hero: '../../../assets/images/hero-cello-v2.jpg',
      backgroundImage: 'assets/images/bg.jpg',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      bodyClass: 'cello2022herbst-page',
      siteRoot: 'celloherbst2022',
      videoRoot: 'konzerte/cello2022herbst',
      videos: [
        {
          thumbnail: '01.jpg',
          title: '„House of the Rising Sun“',
          artist: 'Conny, Zoe, Enisa, Simone, Vroni, Benni, Alex',
          videoFile: '01-RisingSun.mp4',
        },
        {
          thumbnail: '02.jpg',
          title: '„Paris Café“ und „Prelude“',
          artist: 'Simone',
          videoFile: '02-Simone.mp4',
        },
        {
          thumbnail: '03.jpg',
          title: '„Hornpipe“ und „Menuett“',
          artist: 'Conny',
          videoFile: '03-Conny.mp4',
        },
        {
          thumbnail: '04.jpg',
          title: '„Scarborough Fair“',
          artist: 'Zoe',
          videoFile: '04-Zoe.mp4',
        },
        {
          thumbnail: '05.jpg',
          title: '„Once Upon a December“ und „Perfect“',
          artist: 'Alex',
          videoFile: '05-Alex.mp4',
        },
        {
          thumbnail: '06.jpg',
          title: '„Andachtsjodler“ und „First Noel“',
          artist: 'Vroni, Benni, Alex',
          videoFile: '06-Andachtsjodler.mp4',
        },
        {
          thumbnail: '07.jpg',
          title: '„Ding Dong! Merrily On High“ und „I Saw Three Ships“',
          artist: 'Zoe, Conny, Vroni, Benni, Alex',
          videoFile: '07-DingDong.mp4',
        },
        {
          thumbnail: '08.jpg',
          title: '„O Come All Ye Faithful“',
          artist: 'Simone, Enisa, Conny, Vroni, Zoe, Benni',
          videoFile: '08-Faithful.mp4',
        },
        {
          thumbnail: '09.jpg',
          title: '„Away in a Manger“ und „Zither Carol“',
          artist: 'Zoe, Enisa, Simone, Vroni, Benni, Alex',
          videoFile: '09-Manger.mp4',
        },
        {
          thumbnail: '10.jpg',
          title: '„Little Bells of Christmas“ und „Santa Claus is Coming“',
          artist: 'Vroni, Zoe, Benni, Alex',
          videoFile: '10-Kanon.mp4',
        },
      ],
    },
    {
      name: 'Sommerkonzert 2023',
      title: 'Sommerkonzert am 12. Juli 2023',
      p: 'cellosommer2023',
      primaryColor: 'rgb(0,95,180)',
      hero: '../../../assets/images/hero-cello-v3.jpg',
      backgroundImage: 'assets/images/bg.jpg',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      bodyClass: 'cello2023sommer-page',
      siteRoot: 'cellosommer2023',
      videoRoot: 'konzerte/cello2023sommer',
      videos: [
        {
          thumbnail: '01.jpg',
          title: '„Medieval tale & Chase in the dark“',
          artist: 'Julika',
          videoFile: '01.mp4',
        },
        {
          thumbnail: '02.jpg',
          title: '„Going Fourth“ & „City Streets“',
          artist: 'Cornelia',
          videoFile: '02.mp4',
        },
        {
          thumbnail: '03.jpg',
          title: '„Beyond the horizon“',
          artist: 'Zoe',
          videoFile: '03.mp4',
        },
        {
          thumbnail: '04.jpg',
          title: '„Adagio“ (Händel)',
          artist: 'Vroni',
          videoFile: '04.mp4',
        },
        {
          thumbnail: '05.jpg',
          title: '„Invention“ (Dancla) und „Meditation“ (Bach)',
          artist: 'Joachim',
          videoFile: '05.mp4',
        },
        {
          thumbnail: '06.jpg',
          title: '„Allegro Apassionato“ (Saint-Saëns)',
          artist: 'Benjamin',
          videoFile: '06.mp4',
        },
        {
          thumbnail: '07.jpg',
          title: '„Song from a Secret Garden“ (Løvland)',
          artist: 'Alex',
          videoFile: '07.mp4',
        },
        {
          thumbnail: '08.jpg',
          title: '„Der Schwan“ (Saint-Saëns)',
          artist: 'Jonas',
          videoFile: '08.mp4',
        },
        {
          thumbnail: '09.jpg',
          title: '„Spanische Fantasie“ (Köppen)',
          artist: 'Dominik',
          videoFile: '09.mp4',
        },
        {
          thumbnail: '10.jpg',
          title: '„Marussja“ (Varner)',
          artist: 'Ensemble',
          videoFile: '10.mp4',
        },
        {
          thumbnail: '11.jpg',
          title: '„Karussell“ (Varner)',
          artist: 'Ensemble',
          videoFile: '11.mp4',
        },
        {
          thumbnail: '12.jpg',
          title: '„Kanon Dubadabda“',
          artist: 'Ensemble',
          videoFile: '12.mp4',
        },
      ],
    },
    {
      name: 'Herbstkonzert 2023',
      title: 'Herbstkonzert am 22. November 2023',
      p: 'celloherbst2023',
      primaryColor: 'rgb(0,95,180)',
      hero: '../../../assets/images/hero-cello-v2.jpg',
      backgroundImage: 'assets/images/bg.jpg',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      bodyClass: 'cello2023herbst-page',
      siteRoot: 'celloherbst2023',
      videoRoot: 'konzerte/cello2023herbst',
      videos: [
        {
          thumbnail: '01.jpg',
          title: '„Pick a bale of Cotton“',
          artist: 'Julika',
          videoFile: '01.mp4',
        },
        {
          thumbnail: '03.jpg',
          title: '„Sonatina“ - C. Ph. E. Bach',
          artist: 'Joachim',
          videoFile: '03.mp4',
        },
        {
          thumbnail: '04.jpg',
          title: '„Prayer“',
          artist: 'Jonas',
          videoFile: '04.mp4',
        },
        {
          thumbnail: '05.jpg',
          title: '„Rue’s Farewell“ aus „The Hunger Games“ - J. N. Howard',
          artist: 'Benjamin',
          videoFile: '05.mp4',
        },
        {
          thumbnail: '06.jpg',
          title: '„Tango terrible“ - Susanne Paul',
          artist: 'Ensemble',
          videoFile: '06.mp4',
        },
        {
          thumbnail: '07.jpg',
          title: '„Gospels“',
          artist: 'Ensemble',
          videoFile: '07.mp4',
        },
      ],
    },
    {
      name: 'Sommerkonzert 2024',
      title: 'Sommerkonzert am 17. Juli 2024',
      p: 'cellosommer2024',
      primaryColor: 'rgb(0,95,180)',
      hero: '../../../assets/images/hero-cello-v3.jpg',
      backgroundImage: 'assets/images/bg.jpg',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      bodyClass: 'cello2024sommer-page',
      siteRoot: 'cellosommer2024',
      videoRoot: 'konzerte/cello2024sommer',
      videos: [
        {
          thumbnail: '01.jpg',
          title: '„Scarborough Fair“',
          artist: 'Maximilian',
          videoFile: '01.mp4',
        },
        {
          thumbnail: '02.jpg',
          title: '„Seventh Heaven“',
          artist: 'Cornelia',
          videoFile: '02.mp4',
        },
        {
          thumbnail: '03.jpg',
          title: '„Drifting“',
          artist: 'Vroni',
          videoFile: '03.mp4',
        },
        {
          thumbnail: '04.jpg',
          title: '„Soave & Allegro“ – G. Ph. Telemann',
          artist: 'Joachim',
          videoFile: '04.mp4',
        },
        {
          thumbnail: '05.jpg',
          title: '„Kol Nidrei“ - Max Bruch',
          artist: 'Benjamin',
          videoFile: '05.mp4',
        },
        {
          thumbnail: '06.jpg',
          title: '„Gipsy Swing“',
          artist: 'Alex',
          videoFile: '06.mp4',
        },
        {
          thumbnail: '07.jpg',
          title: '„Präludium“ – J. S. Bach',
          artist: 'Jonas',
          videoFile: '07.mp4',
        },
        {
          thumbnail: '08.jpg',
          title: '„Largo & Allegro“',
          artist: 'Dominik',
          videoFile: '08.mp4',
        },
        {
          thumbnail: '09.jpg',
          title: '„La Cumparsita“ – Rodriguez',
          artist: 'Ensemble',
          videoFile: '09.mp4',
        },
        {
          thumbnail: '10.jpg',
          title: '„The Entertainer“ – Scott Joplin',
          artist: 'Ensemble',
          videoFile: '10.mp4',
        },
        {
          thumbnail: '11.jpg',
          title: '„Holiday Rag“',
          artist: 'Ensemble',
          videoFile: '11.mp4',
        },
      ],
    },
  ];

  setActivePortal(portal: Portal) {
    this.activePortal = portal;
  }

  getActivePortal(): Portal {
    return this.activePortal || this.portals[1]; // defaulting to cello
  }

  getPortals(): Portal[] {
    return this.portals;
  }
}
