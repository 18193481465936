import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { User } from '../models/user.interface';
import { FlashService, FlashMessageType } from '../shared/flash/flash.service';
import { Portal } from '../models/portal.interface';
import { PortalService } from './portal.service';

declare var firebase: any;

@Injectable()
export class AuthService {

  private state = new Subject<boolean>();
  private portals: Portal[];

  constructor (
    private router: Router,
    private flashService: FlashService,
    private portalService: PortalService
  ) {
      this.portals = this.portalService.getPortals();
      this.state.subscribe(
        _state => {
          if (_state) {
            localStorage.setItem('active', this.portalService.getActivePortal().siteRoot);
            this.router.navigate([this.portalService.getActivePortal().siteRoot]);
          } else {
            localStorage.removeItem('active');
            this.router.navigate(['login']);
          }
        }
      );
    }

  signInUser(user: User) {
    let success = false;

    this.portals.map( portal => {
      if (portal.p === user.password) {
        this.portalService.setActivePortal(portal);
        this.state.next(true);
        success = true;

      }
    });

    if (!success) {
      this.flashService.show('Kein Zugang mit diesem Passwort möglich. Bitte prüfen Sie Ihre Eingabe.', 5000, FlashMessageType.Danger);
      this.state.next(false);
    }
  }

  logout() {
    this.state.next(false);
  }
}
