import { Component, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';
import { Video } from '../../../models/video.interface';
import { resize } from '../../../shared/animations';

@Component({
  selector: 'app-seminar-video',
  templateUrl: './seminar-video.component.html',
  styleUrls: ['./seminar-video.component.scss'],
  animations: [resize]
})
export class SeminarVideoComponent implements OnInit {
  @Input() video: Video;
  @Input() index: number;
  videoState = 'paused';
  supportsFullscreen = false;
  elem;

  constructor(private renderer: Renderer2) { }

  onPlayFullscreen() {
    const fullscreen = this.elem['webkitRequestFullscreen'] || this.elem['mozRequestFullScreen'] || this.elem['msRequestFullscreen'];
    fullscreen.call(this.elem); // bind the 'this' from the video object and instantiate the correct fullscreen method.
  }

  onPlayVideo() {
    console.log('playing');

    this.videoState = 'playing';
  }

  onDone() {
    console.log('paused');

    this.videoState = 'paused';
  }

  onTrack(title: string) {
    console.log('tracking: ' + title);

    (<any>window).gtag('event', 'play', {
      'event_category': 'Videos - Seminar 2018',
      'event_label': title
    });
  }

  ngOnInit() {
    this.elem = document.getElementsByTagName('video')[this.index];
    this.supportsFullscreen = this.elem['webkitRequestFullscreen'] || this.elem['mozRequestFullScreen'] || this.elem['msRequestFullscreen'];
  }

}
