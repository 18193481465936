import { Injectable } from '@angular/core';
import { Video } from '../models/video.interface';

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  private videos: Video[] = [{
    thumbnail: '03.jpg',
    title: 'My Lighthouse',
    artist: 'Petra',
    videoFile: '03-Petra-MyLighthouse.mp4'
},
{
    thumbnail: '04.jpg',
    title: 'Psalm 177',
    artist: 'Susanne Frankholz',
    videoFile: '04-SusanneFrankholz-Psalm117.mp4'
},
{
    thumbnail: '05.jpg',
    title: 'Alles wird gut',
    artist: 'Susanne Frankholz',
    videoFile: '05-SusanneFrankholz-AllesWirdGut.mp4'
},
{
    thumbnail: '07.jpg',
    title: 'Halleluja',
    artist: 'Ternim',
    videoFile: '07-Halleluja.mp4'
},
{
    thumbnail: '08.jpg',
    title: 'Ternim',
    artist: 'Ternim',
    videoFile: '08-Ternim.mp4'
},
{
    thumbnail: '10.jpg',
    title: 'Mbube / the lion',
    artist: 'mix \'n\' free',
    videoFile: '10-mixnfree-Mbube.mp4'
},
{
    thumbnail: '11.jpg',
    title: 'Summer Wine',
    artist: 'Spontanband',
    videoFile: '11-Spontanband-SummerWine.mp4'
},
{
    thumbnail: '13.jpg',
    title: 'Pray',
    artist: 'van Dooren / Ammon',
    videoFile: '13-vanDooren_Ammon-Pray.mp4'
},
{
    thumbnail: '14.jpg',
    title: 'Ain\'t no mountain',
    artist: 'van Dooren / Ehrensperger',
    videoFile: '14-vanDooren_Ehrensperger-AintNoMountain.mp4'
},
{
    thumbnail: '16.jpg',
    title: 'Umsonst',
    artist: 'Christian Probst',
    videoFile: '16-ChristianProbst-Umsonst.mp4'
},
{
    thumbnail: '17.jpg',
    title: 'Nadelöhr',
    artist: 'WanWan Mey',
    videoFile: '17-WanWanMey-Nadeloehr.mp4'
},
{
    thumbnail: '18.jpg',
    title: 'Nachtgebet',
    artist: 'WanWan Mey',
    videoFile: '18-WanWanMey-Nachtgebet.mp4'
},
{
  thumbnail: '19.jpg',
  title: 'Du bist immer da',
  artist: 'Andreas Buchner',
  videoFile: '19-AndreasBuchner-DuBistImmerDa.mp4'
},
{
    thumbnail: '20.jpg',
    title: 'Gott segne Dich',
    artist: 'Ehrensperger ... ',
    videoFile: '20-GottSegneDich.mp4'
}];
  private activeVideo: Video;

  constructor() { }

  setVideo(video: Video) {
    this.activeVideo = video;
  }

  getActiveVideo(): Video {
    return this.activeVideo;
  }

  getVideos(): Video[] {
    return this.videos;
  }
}
