import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { Video } from '../../models/video.interface';
import { resize } from '../animations';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  animations: [resize],
})
export class VideoComponent implements OnInit {
  @Input() portalName: string;
  @Input() videoRoot: string;
  @Input() video: Video;
  @Input() index: number;
  videoState = 'paused';
  supportsFullscreen = false;
  elem;

  constructor() {}

  onPlayFullscreen() {
    const fullscreen =
      this.elem['webkitRequestFullscreen'] ||
      this.elem['mozRequestFullScreen'] ||
      this.elem['msRequestFullscreen'];
    fullscreen.call(this.elem); // bind the 'this' from the video object and instantiate the correct fullscreen method.
  }

  onPlayVideo() {
    this.videoState = 'playing';
  }

  onDone() {
    this.videoState = 'paused';
  }

  onTrack(title: string, category: string) {
    // console.log('tracking "' + title + '" in category "' + category + '"');

    (<any>window).gtag('event', 'play', {
      event_category: category,
      event_label: title,
    });
  }

  ngOnInit() {
    this.elem = document.getElementsByTagName('video')[this.index];
    this.supportsFullscreen =
      this.elem['webkitRequestFullscreen'] ||
      this.elem['mozRequestFullScreen'] ||
      this.elem['msRequestFullscreen'];
  }
}
