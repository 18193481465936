import { Component, OnInit, OnDestroy, Renderer2} from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { Title } from '@angular/platform-browser';
import { Video } from '../../models/video.interface';
import { Portal } from 'src/app/models/portal.interface';
import { PortalService } from 'src/app/service/portal.service';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit, OnDestroy {
  portal: Portal;
  videos: Video[];

  constructor(
    private authService: AuthService,
    private renderer: Renderer2,
    private titleService: Title,
    private portalService: PortalService
  ) { }


  onLogout() {
    this.authService.logout();
  }

  ngOnInit() {
    this.portal = this.portalService.getActivePortal();
    this.videos = this.portal.videos;
    this.titleService.setTitle(this.portal.title);
    this.renderer.addClass(document.body, this.portal.bodyClass);
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, this.portal.bodyClass);
  }

}
