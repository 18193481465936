import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { User } from '../models/user.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class HttpService {
  constructor(private httpClient: HttpClient) {}

  sendData(user: User) {
    const body = JSON.stringify(user);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.httpClient.post('https://it-bytes.firebaseio.com/portal.json', body, {headers: headers});
  }

  getData() {
    return this.httpClient.get('https://it-bytes.firebaseio.com/portal.json')
      .pipe(map((response: Response) => {
        const data = response.json();
        const returnArray = [];
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            returnArray.push(data[key]);
          }
        }
        return returnArray;
      }));
  }
}

