import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PortalService } from 'src/app/service/portal.service';

@Injectable({
  providedIn: 'root'
})
export class PortalGuard implements CanActivate {

  constructor(
    private portalService: PortalService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return localStorage.getItem('active') === this.portalService.getActivePortal().siteRoot;
  }
}
