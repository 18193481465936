import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../service/auth.service';
import { Injectable } from '@angular/core';

@Injectable()
export class SeminarGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return localStorage.getItem('active') === 'seminar2018';

    // return (localStorage.getItem('active') === 'seminar2018');

    // const _user = this.authService.getCurrentUser();
    // return (_user && _user.uid === 'Mq7vRNNVWgZ4KQL5IkQnUFeuC8J3');


    // this.authService.isAuthenticated().subscribe(
    //   user => {
    //     console.log('user: ' + user.uid);
    //     const _activate = (user && user.uid === 'Mq7vRNNVWgZ4KQL5IkQnUFeuC8J3');

    //     console.log('_activate: ' + _activate);

    //     if (_activate) {
    //       this.router.navigate(['seminar2018']);
    //     } else {
    //       this.router.navigate(['login']);
    //     }

    //     return _activate;
    //   }
    // );



    // return (_activate !== null) ? true : false;
  }
}
