import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { LoginComponent } from './shared/login/login.component';
import { SeminarComponent } from './pages/seminar-2018/seminar.component';
import { routing } from './app.routing';
import { HttpService } from './service/http.service';
import { AuthService } from './service/auth.service';
import { FlashComponent } from './shared/flash/flash.component';
import { TimebarComponent } from './shared/flash/timebar/timebar.component';
import { SeminarGuard } from './pages/seminar-2018/seminar.guard';
import { SeminarVideoComponent } from './pages/seminar-2018/seminar-video/seminar-video.component';
import { VideoComponent } from './shared/video/video.component';
import { PortalComponent } from './shared/portal/portal.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SeminarComponent,
    FlashComponent,
    TimebarComponent,
    SeminarVideoComponent,
    VideoComponent,
    PortalComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    routing
  ],
  providers: [
    HttpService,
    AuthService,
    SeminarGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
