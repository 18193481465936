import { Component, ViewChild } from '@angular/core';
import { FlashService } from './flash.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { TimebarComponent } from './timebar/timebar.component';

@Component({
  selector: 'app-flash',
  templateUrl: './flash.component.html',
  styleUrls: ['./flash.component.scss'],
  animations: [
    trigger('messageState', [
        transition('void => *', [
            style({transform: 'translateY(-100%)'}),
            animate('200ms ease-out')
        ]),
        transition('* => void', [
            animate('200ms ease-in', style({opacity: '0'}))
        ])
    ])
  ]
})

export class FlashComponent {
  active = false;
  message: string;
  activeClass: string;

  private duration: number;
  private timeout: any;

  constructor(private flashService: FlashService) {
    this.flashService.show = this.show.bind(this);
    this.flashService.hide = this.hide.bind(this);
  }

  @ViewChild(TimebarComponent, {static: false}) set tb(timeBar: TimebarComponent) {

    if (typeof(timeBar) !== 'undefined') {
        timeBar.startTimer(this.duration);
    }
  }

  show(message: string, duration = 3000, type?: string) {
    this.message = message;
    this.active = true;
    this.duration = duration;

    if (type) {
      this.activeClass = type;
    }

    this.timeout = setTimeout(() => {
        this.active = false;
    }, duration);
  }

  hide() {
      this.active = false;
      clearTimeout(this.timeout);
  }
}
