import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './shared/login/login.component';
import { PortalComponent } from './shared/portal/portal.component';
import { PortalGuard } from './shared/portal/portal.guard';

const APP_ROUTES: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'seminar2018',
    component: PortalComponent,
    canActivate: [PortalGuard],
  },
  {
    path: 'schuelerkonzert2018',
    component: PortalComponent,
    canActivate: [PortalGuard],
  },
  {
    path: 'cellosommer2019',
    component: PortalComponent,
    canActivate: [PortalGuard],
  },
  {
    path: 'celloherbst2019',
    component: PortalComponent,
    canActivate: [PortalGuard],
  },
  {
    path: 'cellosommer2021',
    component: PortalComponent,
    canActivate: [PortalGuard],
  },
  {
    path: 'celloherbst2021',
    component: PortalComponent,
    canActivate: [PortalGuard],
  },
  {
    path: 'cellosommer2022',
    component: PortalComponent,
    canActivate: [PortalGuard],
  },
  {
    path: 'celloherbst2022',
    component: PortalComponent,
    canActivate: [PortalGuard],
  },
  {
    path: 'cellosommer2023',
    component: PortalComponent,
    canActivate: [PortalGuard],
  },
  {
    path: 'celloherbst2023',
    component: PortalComponent,
    canActivate: [PortalGuard],
  },
  {
    path: 'cellosommer2024',
    component: PortalComponent,
    canActivate: [PortalGuard],
  },
];

export const routing = RouterModule.forRoot(APP_ROUTES);
