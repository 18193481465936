import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private titleService: Title,
    private router: Router
  ) {
    this.titleService.setTitle('Portal');

    this.router.events.subscribe(event => {
     if (event instanceof NavigationEnd) {
      (<any>window).gtag('event', 'page_view', { 'send_to': 'UA-126975410-1' });
     }
   });
 }

}
