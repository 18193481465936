import { Component, OnInit, OnDestroy, Renderer2} from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { Title } from '@angular/platform-browser';
import { VideoService } from '../../service/video.service';
import { Video } from '../../models/video.interface';

@Component({
  selector: 'app-seminar',
  templateUrl: './seminar.component.html',
  styleUrls: ['./seminar.component.scss']
})
export class SeminarComponent implements OnInit, OnDestroy {
  videos: Video[];
  private title = 'Popularmusikverband - Seminar 2018';

  constructor(
    private authService: AuthService,
    private renderer: Renderer2,
    private titleService: Title,
    private videoService: VideoService
  ) { }


  onLogout() {
    this.authService.logout();
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'seminar-page');
    this.titleService.setTitle(this.title);
    this.videos = this.videoService.getVideos();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'seminar-page');
  }

}
