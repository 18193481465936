import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;

  constructor(
    private renderer: Renderer2,
    private authService: AuthService,
    private fb: FormBuilder,
    private titleService: Title) {}

  onSignIn() {
    this.authService.signInUser(this.loginForm.value);
  }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'login-page');
    this.titleService.setTitle('Portalzugang');
    this.loginForm = this.fb.group({
      password: [null, Validators.required]
    });
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'login-page');
  }
}
