import { animate, style, transition, state, keyframes, trigger } from '@angular/animations';

export const resize = trigger('resize', [
  // the "in" style determines the "resting" state of the element when it is visible.
  state('paused', style({
    width: '335px',
    'margin-left': 0
  })),
  state('playing', style({
    width: '100%',
    'margin-left': '-20px'
  })),
  transition('* => *', animate(300))
]);
